import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SpinWheel from './SpinWheel'; // Import the SpinWheel component
import BackgroundMusic from './components/BackgroundMusic'; // Import the BackgroundMusic component

function App() {
  const [user, setUser] = useState(null);
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [loginError, setLoginError] = useState('');
  const [volume, setVolume] = useState(() => parseFloat(sessionStorage.getItem('volume')) || 0.5); // Volume state
  const [isMuted, setIsMuted] = useState(() => sessionStorage.getItem('muted') === 'true'); // Mute state

  // Save volume and mute to sessionStorage when they change
  useEffect(() => {
    sessionStorage.setItem('volume', volume);
    sessionStorage.setItem('muted', isMuted);
  }, [volume, isMuted]);

  // Check for user data in localStorage on page load
  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser)); // If user data exists, set the user state
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginData),
      });
      const data = await response.json();

      if (data.error) {
        setLoginError(data.error);
      } else {
        setUser(data);  // Set the user data after successful login
        localStorage.setItem('user', JSON.stringify(data)); // Save user data in localStorage
        setLoginError('');
      }
    } catch (error) {
      setLoginError('Login failed');
    }
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user'); // Clear user data from localStorage
  };

  return (
    <div className="App">
      {/* Background Music Component with volume and mute control */}
      <BackgroundMusic
        volume={volume} // Pass volume state
        isMuted={isMuted} // Pass mute state
        onVolumeChange={setVolume} // Pass function to update volume
        onMuteToggle={setIsMuted} // Pass function to update mute state
      />

      {!user ? (
        <>
          <div className="col-lg-12 text-center">
            <center>
              <h1 className="text-shadow-inverted header-spin" style={{ textTransform: 'uppercase' }}>Spin Roulette</h1>
            </center>
          </div>
          <div className="container">
            <section id="content">
              <form onSubmit={handleLogin}>
                <h1>Login</h1>
                <div>
                  <input
                    type="text"
                    placeholder="Username"
                    value={loginData.username}
                    onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
                    required
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Password"
                    value={loginData.password}
                    onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                    required
                  />
                </div>
                <div>
                  {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
                </div>
                <div>
                  <input type="submit" value="Log in" style={{ float: 'right', marginRight: '37px' }} />
                </div>
              </form>
            </section>
          </div>
        </>
      ) : (
        <>
          {/* Spin Wheel with shared volume and mute control */}
          <SpinWheel
            user={user}
            volume={volume} // Pass volume to SpinWheel
            isMuted={isMuted} // Pass mute state to SpinWheel
          />
          <button onClick={handleLogout} className="btn btn-secondary mt-3">
            Logout
          </button>
        </>
      )}
    </div>
  );
}

export default App;
