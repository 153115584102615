import { useState, useEffect } from 'react';
import styles from './Table.module.css';

export default function HistoryTable({ userId, refreshFlag }) {
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0); // Total records in the history
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const limit = 10; // Number of records per page

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch history whenever the component is mounted, page is changed, or refreshFlag is triggered
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await fetch('/api/get-spin-history', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId, page, limit }),
        });
        const data = await response.json();
        setHistory(data.history);
        setTotalRecords(data.total); // Set total number of records
      } catch (error) {
        console.error('Error fetching history:', error);
      }
    };

    fetchHistory();
  }, [userId, page, refreshFlag]); // Re-fetch when userId, page, or refreshFlag changes

  const toggleHistoryTable = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalRecords / limit)) {
      setPage(newPage);
    }
  };

  return (
    <div className={styles.historyContainer}>
      {!isMobile && (
        <div className={styles.historyTable}>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Item</th>
               {/*  <th>Type</th> */}
              </tr>
            </thead>
            <tbody>
              {history.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.id}</td>
                  <td>{new Date(entry.createdAt).toLocaleDateString()}</td>
                  {/* <td>{entry.prize.name}</td> */}
                  <td>{entry.prizeName}</td>
             {/*      <td>{entry.prize.type}</td> */}
                </tr>
              ))}
              <tr>
                <td colSpan={4}>
                  <div className={styles.pagination}>
                    <span>
                      {page > 1 && <span style={{cursor:'pointer'}} onClick={() => handlePageChange(page - 1)}>◀️</span>}
                      {` ${(page - 1) * limit + 1}-${Math.min(page * limit, totalRecords)} of ${totalRecords} `}
                      {page < Math.ceil(totalRecords / limit) && <span style={{cursor:'pointer'}} onClick={() => handlePageChange(page + 1)}>▶️</span>}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {isMobile && (
        <>
          <button className={styles.historyToggleButton} onClick={toggleHistoryTable}>
            🕒
          </button>
          {isModalOpen && (
            <div className={styles.historyModalOverlay}>
              <div className={styles.historyModal}>
                <div className={styles.modalHeader}>
                  <h2 className={styles.textShadow}>History</h2>
                  <button onClick={toggleHistoryTable}>✖</button>
                </div>
                <div className={styles.modalContent}>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Item</th>
                       {/*  <th>Type</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {history.map((entry, index) => (
                        <tr key={index}>
                          <td>{entry.id}</td>
                          <td>{new Date(entry.createdAt).toLocaleDateString()}</td>
                          <td>{entry.prizeName}</td>
                          {/* <td>{entry.prize.name}</td> */}
                         {/*  <td>{entry.prize.type}</td> */}
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4}>
                          <div className={styles.pagination}>
                            <span>
                              {page > 1 && <span style={{cursor:'pointer'}} onClick={() => handlePageChange(page - 1)}>◀️</span>}
                              {` ${(page - 1) * limit + 1}-${Math.min(page * limit, totalRecords)} of ${totalRecords} `}
                              {page < Math.ceil(totalRecords / limit) && <span style={{cursor:'pointer'}} onClick={() => handlePageChange(page + 1)}>▶️</span>}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
