import React, { useState, useEffect, useRef } from 'react';
import './BackgroundMusic.css'; // Import the CSS

const BackgroundMusic = () => {
  const playlist = [
    '/assets/funk-casino-163105.mp3',
    '/assets/casino-164235.mp3',
    '/assets/funk-upbeat-lottery-casino-pause-intro-background-intro-music-120443.mp3',
  ];

  const getRandomSong = () => playlist[Math.floor(Math.random() * playlist.length)];

  const audioRef = useRef(new Audio(getRandomSong())); // Create a reference for the background music

  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(() => parseFloat(localStorage.getItem('volume')) || 0.5);
  const [isMuted, setIsMuted] = useState(() => localStorage.getItem('muted') === 'true');
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audioRef.current.volume = isMuted ? 0 : newVolume;
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audioRef.current.volume = !isMuted ? 0 : volume;
  };

  const handleUserInteraction = () => {
    if (!isMuted) {
      audioRef.current.play().catch((error) => {
        console.error('Autoplay error:', error);
      });
      setIsPlaying(true);
    }
    // Remove the event listener after the first interaction
    window.removeEventListener('click', handleUserInteraction);
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.loop = false;
    audio.volume = volume;

    // Add event listener for user interaction (click)
    window.addEventListener('click', handleUserInteraction);

    const handleEnded = () => {
      const newSong = getRandomSong();
      audioRef.current.src = newSong;
      if (isPlaying && !isMuted) {
        audioRef.current.play();
      }
    };

    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.pause();
      audio.removeEventListener('ended', handleEnded);
      window.removeEventListener('click', handleUserInteraction);
    };
  }, [isPlaying, volume, isMuted]);

  useEffect(() => {
    localStorage.setItem('volume', volume);
    localStorage.setItem('muted', isMuted);
  }, [volume, isMuted]);

  return (
    <>
      <div className="volume-control-icon" onClick={() => setIsPopupVisible(!isPopupVisible)}>
        <span role="img" aria-label="volume">
          {isMuted ? '🔇' : '🔊'}
        </span>
      </div>

      {isPopupVisible && (
        <div className="volume-popup">
          <h4>Volume Settings</h4>
          <button className="mute-btn" onClick={toggleMute}>
            {isMuted ? 'Unmute' : 'Mute'}
          </button>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            disabled={isMuted}
          />
        </div>
      )}
    </>
  );
};

export default BackgroundMusic;
