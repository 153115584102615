import { useState, useEffect } from 'react';
import styles from './Table.module.css';

export default function CharacterTable({ userId }) {
  const [characters, setCharacters] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(5); // Number of records per page
  const [totalRecords, setTotalRecords] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch character data with pagination when the component mounts or when userId or page changes
  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const response = await fetch(`/api/get-characters/${userId}?page=${page}&limit=${limit}`);
        const data = await response.json();
        setCharacters(data.characters); // Set the fetched characters in the state
        setTotalRecords(data.total); // Set the total number of records
      } catch (error) {
        console.error('Failed to fetch characters:', error);
      }
    };

    if (userId) {
      fetchCharacters();
    }
  }, [userId, page]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalRecords / limit)) {
      setPage(newPage); // Update the current page
    }
  };

  const toggleHistoryTable = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={styles.historyContainer}>
      {!isModalOpen && (
        <div className={styles.historyTable}>
          <table>
            <thead>
              <tr>
                <th>Character ID</th>
                <th>Character Name</th>
                <th>Level</th>
              </tr>
            </thead>
            <tbody>
              {characters.map((character) => (
                <tr key={character.ChaNum}>
                  <td>{character.ChaNum}</td>
                  <td>{character.ChaName}</td>
                  <td>{character.ChaLevel}</td>
                </tr>
              ))}
              <tr>
              <td colSpan={1}>
                  <div>
                    <span className={styles.hoverable} onClick={toggleHistoryTable}>Hide</span>
                  </div>
                </td>
                <td colSpan={3}>
                  <div className={styles.pagination}>
                    <span>
                      {page > 1 && <span style={{ cursor: 'pointer' }} onClick={() => handlePageChange(page - 1)}>◀️</span>}
                      {` ${(page - 1) * limit + 1}-${Math.min(page * limit, totalRecords)} of ${totalRecords} `}
                      {page < Math.ceil(totalRecords / limit) && <span style={{ cursor: 'pointer' }} onClick={() => handlePageChange(page + 1)}>▶️</span>}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && !isMobile && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'fixed',
            top: '50%',
            left: '0',
            height: '80px',
            background: '#1b51a1',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            width: '15px',
            textAlign: 'right',
            paddingRight: '0px',
            cursor: 'pointer',
          }}
          onClick={toggleHistoryTable}
        >
          <span
            style={{
              color: '#fff',
              cursor: 'pointer',
              fontSize: '24px',
            }}
            onClick={toggleHistoryTable}
          >
            ➧
          </span>
        </div>
      )}
    </div>
  );
}
