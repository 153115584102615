import React from 'react';
import './Wheel.css';

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
      selectedItem: null,
      spinDuration: '7s',
      spinTimingFunction: 'ease-out',
    };
    this.spinSound = new Audio('/assets/wheel-sound.mp3'); // Load spin sound
  }

  componentDidUpdate(prevProps) {
    // Update the spin sound volume and mute state whenever the props change
    if (prevProps.volume !== this.props.volume || prevProps.isMuted !== this.props.isMuted) {
      this.spinSound.volume = this.props.isMuted ? 0 : this.props.volume;
    }

    if (prevProps.selectedItem !== this.props.selectedItem && this.props.selectedItem !== null) {
      this.setState({ 
        spinning: true, 
        selectedItem: this.props.selectedItem,
      }, () => {
        this.playSpinSound();
      
        setTimeout(() => {
          if (this.props.onSpinEnd) {
            this.props.onSpinEnd();
          }
        }, parseFloat(this.state.spinDuration) * 1000);
      });
    }
  }

  playSpinSound = () => {
    // Ensure the volume and mute state are correctly applied each time the sound plays
    this.spinSound.volume = this.props.isMuted ? 0 : this.props.volume;
    this.spinSound.play();
    
  }

  resetWheel = () => {
    this.setState({
      spinning: false,
      selectedItem: null,
    });
  }

  render() {
    const { items } = this.props;
    const { spinning, selectedItem, spinDuration, spinTimingFunction } = this.state;

    const wheelVars = {
      '--nb-item': items.length,
      '--selected-item': selectedItem,
      '--nb-turns': 30,
      '--spin-duration': spinDuration,
      '--spin-timing-function': spinTimingFunction,
    };

    return (
      <div className={`wheel-container ${this.props.spinType}-spin`}>
        <div className={`wheel ${spinning ? 'spinning' : ''}`} style={wheelVars}>
          {items.map((item, index) => (
            <div
              className={`wheel-item ${item.type === 'PremiumSpin' ? 'special-item-premium' : item.type === 'JackpotSpin' ? 'special-item-jackpot' : ''}`}
              key={index}
              style={{ '--item-nb': index }}
            >
              <span className='wheelItem'>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
